import React from 'react';

const AboutUs = () => {
  const employees = [
    {
      name: 'Employee 1',
      bio: 'Bio for Employee 1',
      photo: '/path/to/employee1.jpg',
    },
    {
      name: 'Employee 2',
      bio: 'Bio for Employee 2',
      photo: '/path/to/employee2.jpg',
    },
    // Add more employees as needed
  ];

  return (
    <div>
      {employees.map((employee, index) => (
        <div key={index}>
          <h2>{employee.name}</h2>
          <p>{employee.bio}</p>
          <img src={employee.photo} alt={employee.name} />
        </div>
      ))}
    </div>
  );
};

export default AboutUs;
