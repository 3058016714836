import React, { useState } from "react";
import "./LandingPage.css"; // Import the CSS file for the LandingPage component
import logo from "../logo.png"; // Adjust the path based on your project structure
// import ReCAPTCHA from "react-google-recaptcha";

const LandingPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  //   const [recaptchaValue, setRecaptchaValue] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (!recaptchaValue) {
    //     console.error("Please complete the reCAPTCHA verification.");
    //     return;
    //   }
    const data = {
      Summary: "New Contact Form Submission",
      Text: `Name: ${name}<br>Email: ${email}<br>Message: ${message}`
    };

    try {
      const response = await fetch("https://sendtoteamswebhook-zdn6japp4a-uc.a.run.app", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      });
      let resp_text = await response.text();
      if (response.ok) {
        console.log(
          "Data sent successfully to Firebase Cloud Function.",
          resp_text
        );
        setShowMessage(true);
      } else {
        console.error("Failed to send data to Firebase Cloud Function.");
      }
    } catch (error) {
      console.error(
        "An error occurred while sending data to Firebase Cloud Function:",
        error
      );
    }
  };

  return (
    <div className="landing-page">
      <div className="banner">
        <img src={logo} alt="Brapps Logo" />
        <h3>We solve problems</h3>
        <p>
          Our experts will listen to your needs and build a custom solution for
          you.
        </p>
        <p>
          Our goals are to build low cost and low maintence options that
          leverage software you already pay for.
        </p>
        <p>
          We specialize in NetSuite, Odoo, Shopify, Amazon, and many other
          platforms.
        </p>
        <p>
          We have built solutions for warehouse management, sales automation,
          vendor management, dashboards, EDI and purchasing.
        </p>
        <p>Let us solve your problems today!</p>
      </div>
      {!showMessage && (
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              pattern="^[\w.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            ></textarea>
          </div>
          {/* <div className="form-group">
            <ReCAPTCHA
              sitekey="6LdyFckmAAAAAAPEe5V3oD24XzsFmQgwv5Pp73R"
              onChange={(value) => setRecaptchaValue(value)}
            />
          </div> */}
          <div className="form-group">
            <button type="submit">Submit</button>
          </div>
        </form>
      )}
      {showMessage && (
        <div className="success-message">
          <h3 className="centered">
            Thank you for your message! We will contact you shortly.
          </h3>
        </div>
      )}
    </div>
  );
};

export default LandingPage;
